<template>
  <div class="meta-box" v-cloak>
    <!-- activate area -->
    <div v-if="participantStatus === 'S'">
      <div class="title-container">
        <h3 class="title">
          {{ $t('promotion.firstTimeDeposit.active.title') }}
        </h3>
      </div>
      <el-row :gutter="20" class="sub-box1">
        <el-col :xs="24">
          <div class="sub-title">
            {{ $t('promotion.firstTimeDeposit.active.desc') }}
          </div>
        </el-col>
        <el-col :xs="24" class="button-box opt-button-box">
          <el-button
            class="el-button btn-blue capitalize long-button"
            @click="onActivateCampaign"
            data-testid="participateNow"
          >
            {{ $t('promotion.firstTimeDeposit.button.active') }}
          </el-button>
        </el-col>
      </el-row>
    </div>

    <!-- voucher panel -->
    <VoucherPanel
      v-if="participantStatus !== 'S'"
      :participantStatus="participantStatus"
      :voucher="voucher"
      :cumulativeLot="cumulativeLot"
      :campaignInfo="{
        mt4Account: participateAccount,
        formatAccount: getFormatParticipateAccount,
        campaignID: campaignID
      }"
      @successRedeem="onSuccessRedeem"
    />

    <el-row
      :gutter="20"
      class="sub-box2"
      :class="{ 'dotted-bottom': participantStatus === 'E' || participantStatus === 'DP' || participantStatus === 'I' }"
    >
      <!-- How to claim -->
      <el-col :xs="24" v-if="participantStatus === 'S'">
        <Description
          :title="$t('promotion.firstTimeDeposit.claimTitle')"
          :descriptions="$t('promotion.firstTimeDeposit.claimDescriptions')"
        />
      </el-col>
      <!-- eligibility -->
      <el-col :xs="24">
        <Description :title="$t('promotion.firstTimeDeposit.eligibilityTitle')" useSlotForLiTag>
          <li>{{ $t('promotion.firstTimeDeposit.eligibleDescription1') }}</li>
          <li>{{ $t('promotion.firstTimeDeposit.eligibleDescription2') }}</li>
          <li>{{ $t('promotion.firstTimeDeposit.eligibleDescription3') }}</li>
          <li>{{ $t('promotion.firstTimeDeposit.eligibleDescription4') }}</li>
          <div class="eligible-des">{{ $t('promotion.firstTimeDeposit.eligibleDescription4Desc') }}</div>
          <li>{{ $t('promotion.firstTimeDeposit.eligibleDescription5') }}</li>
          <li>{{ $t('promotion.firstTimeDeposit.eligibleDescription6') }}</li>
        </Description>
      </el-col>
      <!-- found yout account -->
      <el-col
        :xs="24"
        class="button-box"
        v-if="participantStatus === 'S' || participantStatus === 'E' || participantStatus === 'DP'"
      >
        <el-button
          class="el-button btn-blue capitalize long-button"
          data-testid="fund"
          :disabled="participantStatus === 'S'"
          @click="goToFund"
        >
          {{ $t('promotion.firstTimeDeposit.button.fund') }}
        </el-button>
      </el-col>
      <!-- t&c -->
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.cryptoCashback.tnc">
          <template v-slot:link>
            <a :href="tncLink" target="_blank" data-testid="tnc">
              {{ $t('promotion.cryptoCashback.link') }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>

    <!-- deactivate area -->
    <div
      class="deactivate-area"
      v-if="participantStatus === 'E' || participantStatus === 'DP' || participantStatus === 'I'"
    >
      <div class="title-container">
        <h3 class="title">
          {{ $t('promotion.firstTimeDeposit.deactive.title') }}
        </h3>
      </div>
      <el-row :gutter="20" class="sub-box1 border-bottom-none">
        <el-col :xs="24">
          <div class="sub-title">
            {{ $t('promotion.firstTimeDeposit.deactive.desc') }}
          </div>
        </el-col>
        <el-col :xs="24" class="button-box opt-button-box">
          <el-button class="el-button btn-blue long-button" @click="deactivateAndQueryData" data-testid="optOut">
            {{ $t('promotion.firstTimeDeposit.button.deactive') }}
          </el-button>
        </el-col>
      </el-row>
    </div>

    <!-- dialog -->
    <!-- account dialog -->
    <AccountDialog
      :dialogVisible.sync="showAccountDialog"
      :campaignID="campaignID"
      :accountOptions="accountOptions"
      @sendAccountMt4Dto="closeAndOpenTncDialog"
      @close="onCloseAccountDialog"
    >
      <template #accountDialogTitle>
        <p>{{ $t('promotion.firstTimeDeposit.dialog.selectAccountContext') }}</p>
      </template>
    </AccountDialog>

    <!-- agree tnc dialog -->
    <AgreeTnc :agreeTncVisible.sync="showTncDialog" :accountData="accountData" @activateSuccess="onActivateSuccess" />

    <!-- condition dialog -->
    <CallbackDialog
      :show.sync="showResultDialog"
      :callbackType="optStatus"
      @closCbDialog="closCbDialog"
      @optout="optSignOut"
    />
  </div>
</template>

<script>
// api
import { apiGetRedeemableAccounts, apiCampaignOpt } from '@/resource';
import { isSuccess } from '@/util/restApi';
// componenets
import Description from '@/components/description/DescriptionV2.vue';
import DescriptionTable from '@/components/promotion/millionDollarCashPool/DescriptionTable.vue';
import VoucherPanel from '@/components/promotion/firstTimeDeposit/VoucherPanel.vue';
import AccountDialog from '@/components/promotion/common/AccountDialog.vue';
import AgreeTnc from './AgreeTnc.vue';
import CallbackDialog from './CallbackDialog.vue';
// mixins
import blackList from '@/mixins/blackList';
// vuex
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'FtdMainPage',
  components: {
    Description,
    DescriptionTable,
    VoucherPanel,
    AccountDialog,
    AgreeTnc,
    CallbackDialog
  },
  mixins: [blackList],
  data() {
    return {
      showAccountDialog: false,
      showTncDialog: false,
      showResultDialog: false,
      redeemableAccounts: [],
      accountData: null,
      optStatus: null
    };
  },
  computed: {
    ...mapState('promotion/firstTimeDeposit', ['participantStatus', 'participateAccount', 'cumulativeLot', 'voucher']),
    ...mapGetters('promotion/firstTimeDeposit', ['campaignID', 'getFormatParticipateAccount']),
    tncLink() {
      let tncVersion = this.regulator === 'svg' ? '_Net' : '';
      return `https://${this.GLOBAL_REFERRAL_SITE}/pdf/0528/FTD_Cashback_Terms_and_Conditions${tncVersion}.pdf`;
    },
    accountOptions() {
      return this.redeemableAccounts.map(({ mt4Account, currency, datasourceId, accountDisplayType }) => {
        let accountType = this.$config.accountTypeMaps[Number(accountDisplayType)] || null;
        let label = accountType ? `${mt4Account} - ${accountType} - ${currency}` : `${mt4Account} - ${currency}`;
        return {
          label,
          value: {
            mt4Account,
            currency,
            datasourceId
          }
        };
      });
    }
  },
  methods: {
    async refreshEligibleCampaigns() {
      await this.$store.dispatch('promotion/actionGetEligibleCampaigns');
    },
    async onActivateCampaign() {
      try {
        const { data: cbData } = await apiGetRedeemableAccounts(this.campaignID);
        if (isSuccess(cbData)) {
          this.redeemableAccounts = cbData.data;
          this.showAccountDialog = true;
        } else {
          this.closCbDialog();
        }
      } catch (e) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    onCloseAccountDialog() {
      this.showAccountDialog = false;
    },
    closeAndOpenTncDialog(accountMt4Dto) {
      this.accountData = { ...accountMt4Dto, campaignID: this.campaignID };
      this.showAccountDialog = false;
      setTimeout(() => {
        this.showTncDialog = true;
      }, 100);
    },
    closCbDialog() {
      this.showResultDialog = false;
      setTimeout(() => {
        if (this.optStatus === 'failOptout' || this.optStatus === 'successRedeem') {
          this.$router.push({ name: 'home' });
        }
        this.optStatus = null;
      }, 300);
    },
    closeAllDialog() {
      this.showAccountDialog = false;
      this.showTncDialog = false;
      this.closCbDialog();
    },
    goToFund() {
      this.getDepositBlacklist();
    },
    async onActivateSuccess() {
      try {
        await this.refreshEligibleCampaigns();
        this.optStatus = 'success';
        this.showResultDialog = true;
        this.$emit('activeOptIn');
      } catch (error) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    deactivateAndQueryData() {
      this.optStatus = 'failNotOptout';
      this.showResultDialog = true;
    },
    async optSignOut() {
      try {
        const { data: cbData } = await apiCampaignOpt(this.campaignID, false);
        if (isSuccess(cbData)) {
          // code === 0 means opt-out success
          await this.refreshEligibleCampaigns();
          this.optStatus = 'failOptout';
          this.showResultDialog = true;
        } else {
          this.closCbDialog();
          return this.showError();
        }
      } catch (e) {
        this.closeAllDialog();
        return this.showError();
      }
    },
    onSuccessRedeem() {
      this.optStatus = 'successRedeem';
      this.showResultDialog = true;
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .button-box {
  margin-top: 36px !important;
  margin-bottom: 48px !important;
}

/deep/ .agreeTnc-box {
  margin-bottom: 52px !important;
}

.deactivate-area {
  margin-top: 64px;
}

.eligible-des {
  font-size: 14px;
}
</style>
