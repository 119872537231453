<template>
  <div class="voucher-area" v-cloak>
    <div class="voucher-title">{{ $t('promotion.firstTimeDeposit.voucher.title') }}</div>
    <div class="account-content" v-if="onCampaignInfo.formatAccount">
      {{ $t('promotion.firstTimeDeposit.voucher.accountID') }}: {{ onCampaignInfo.formatAccount }}
    </div>
    <div
      class="voucher-content"
      :class="[isVoucherMobile]"
      v-resizeObserver="{
        openResize: true,
        handler: observerCallBack
      }"
    >
      <!-- inactive -->
      <div class="voucher-container" v-if="!isActiveVoucher">
        <div class="voucher-left">
          <div v-if="!ftdLessThan200">
            <BlueTableWrapper class="lot-table">
              <el-table :data="displayData" class="head-gutter" min-height="160">
                <el-table-column>
                  <template #header>
                    <div>{{ $t('promotion.firstTimeDeposit.voucher.accumulateVolume') }}</div>
                    <div>({{ $t('promotion.firstTimeDeposit.voucher.standardLots') }})</div>
                  </template>
                  <template slot-scope="scope"> {{ scope.row.lot }} / 2.00 </template>
                </el-table-column>
              </el-table>
            </BlueTableWrapper>
            <div class="lot-desc">{{ $t('promotion.firstTimeDeposit.voucher.desc') }}</div>
          </div>
          <div class="lot-redeem" v-else>{{ $t('promotion.firstTimeDeposit.voucher.ftdLessThan200') }}</div>
        </div>
        <div class="voucher-right">
          <img
            class="voucher-item"
            :class="{ 'box-shadow': isActiveVoucher === 'active' }"
            :src="getVoucherImage()"
            alt="voucher-image"
          />
        </div>
      </div>

      <!-- active -->
      <div class="voucher-container active" v-else>
        <div class="voucher-left">
          <div class="lot-redeem">{{ $t('promotion.firstTimeDeposit.voucher.clickForRedeem') }}</div>
          <el-button class="btn-blue redeem-btn" @click="redeem">{{ $t('common.button.redeem') }}</el-button>
        </div>
        <div class="voucher-right">
          <img
            class="voucher-item"
            :class="{ 'box-shadow': isActiveVoucher }"
            :src="getVoucherImage()"
            alt="voucher-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiRedeem } from '@/resource';
import { isSuccess } from '@/util/restApi';
import BlueTableWrapper from '@/components/promotion/common/BlueTableWrapper.vue';

export default {
  name: 'VoucherPanel',
  components: {
    BlueTableWrapper
  },
  props: {
    participantStatus: {
      type: String,
      default: 'E'
    },
    voucher: {
      type: Number,
      default: 0
    },
    cumulativeLot: {
      type: Number,
      default: 0
    },
    campaignInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      voucherContentWidth: 0
    };
  },
  computed: {
    displayData() {
      return [
        {
          lot: this.cumulativeLot
        }
      ];
    },
    onParticipantStatus() {
      return this.participantStatus;
    },
    onVoucher() {
      return this.voucher;
    },
    onCampaignInfo() {
      return this.campaignInfo;
    },
    ftdLessThan200() {
      return this.onParticipantStatus === 'I';
    },
    isActiveVoucher() {
      return this.onParticipantStatus === 'RE' && this.onVoucher > 0;
    },
    isVoucherMobile() {
      return Number(this.voucherContentWidth) < 780 ? 'mobile' : '';
    }
  },
  methods: {
    getVoucherImage() {
      let mapKey = this.isActiveVoucher ? 'active' : 'inactive';
      const imageMap = {
        0: {
          inactive: require(`@/assets/images/promotion/firstTimeDeposit/voucher/0-disabled.png`)
        },
        5: {
          active: require(`@/assets/images/promotion/firstTimeDeposit/voucher/5.png`),
          inactive: require(`@/assets/images/promotion/firstTimeDeposit/voucher/5-disabled.png`)
        },
        10: {
          active: require(`@/assets/images/promotion/firstTimeDeposit/voucher/10.png`),
          inactive: require(`@/assets/images/promotion/firstTimeDeposit/voucher/10-disabled.png`)
        },
        20: {
          active: require(`@/assets/images/promotion/firstTimeDeposit/voucher/20.png`),
          inactive: require(`@/assets/images/promotion/firstTimeDeposit/voucher/20-disabled.png`)
        },
        50: {
          active: require(`@/assets/images/promotion/firstTimeDeposit/voucher/50.png`),
          inactive: require(`@/assets/images/promotion/firstTimeDeposit/voucher/50-disabled.png`)
        }
      };
      return imageMap[this.onVoucher][mapKey] ? imageMap[this.onVoucher][mapKey] : imageMap[0]['inactive'];
    },
    observerCallBack(width) {
      this.voucherContentWidth = width;
    },
    async redeem() {
      try {
        const { data: res } = await apiRedeem({
          campaignId: this.onCampaignInfo.campaignID,
          mt4Account: this.onCampaignInfo.mt4Account.mt4Account
        });

        if (isSuccess(res)) {
          this.$emit('successRedeem');
        } else if (res?.code !== 500) {
          this.showError();
        }

        this.$emit('successRedeem');
      } catch (error) {
        this.showError();
      }
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.voucher-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.voucher-title {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 24px;
  font-size: 25px;
  line-height: 36px;
  font-weight: 800;
  text-align: center;
}
.account-content {
  font-size: 16px;
  line-height: 32px;
  font-weight: 800;
}

.voucher-content {
  border-radius: 20px;
  background-color: #ecf5ff;
  .voucher-container {
    width: 100%;
    padding: 52px 44px 52px 44px;
    display: flex;
    justify-content: space-between;
  }

  .voucher-left {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    justify-content: space-between;
  }
  .lot-table {
    margin-bottom: 24px;
  }
  /deep/ .el-table.head-gutter th {
    padding-left: 0;
    padding-right: 0;

    .cell {
      padding: 4px 24px 4px 24px;
    }
  }
  .lot-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $future-blue;
  }

  .lot-redeem {
    font-size: 20px;
    font-weight: 800;
    color: $future-blue;
    text-transform: uppercase;
  }

  .voucher-right {
    min-width: 310px;
  }
  .voucher-item {
    display: block;
    border-radius: 16px;

    &.box-shadow {
      box-shadow: 0px 20px 23px -4px #a5c9ff;
    }
  }

  .redeem-btn {
    max-width: 220px;
  }

  &.mobile {
    .voucher-container {
      flex-direction: column;
      align-items: center;
      padding: 36px;

      &.active {
        flex-direction: column-reverse;

        .voucher-left + .voucher-right {
          margin-bottom: 40px;
          margin-top: 0px;
        }

        .voucher-left {
          align-items: center;
        }

        .redeem-btn {
          min-width: 220px;
        }
      }
    }

    .voucher-left + .voucher-right {
      margin-top: 24px;
    }

    .voucher-left {
      max-width: 100%;
      width: 100%;
    }

    .voucher-right {
      max-width: 100%;
      min-width: 100%;
      display: flex;
      justify-content: center;
    }

    .lot-redeem {
      margin-bottom: 24px;
      text-align: center;
    }
  }
}
</style>
