<template>
  <AgreeTncDialog :agreeTncVisible.sync="visible" :accountData="accountData" @activateSuccess="activateSuccess">
    <div class="header-text text-uppercase">{{ $t('promotion.firstTimeDeposit.dialog.tnc') }}</div>
    <ol>
      <li>{{ $t('promotion.firstTimeDeposit.dialog.tncContext.tnc1') }}</li>
      <li>{{ $t('promotion.firstTimeDeposit.dialog.tncContext.tnc1desc') }}</li>
      <li>{{ $t('promotion.firstTimeDeposit.dialog.tncContext.tnc2') }}</li>
      <li>{{ $t('promotion.firstTimeDeposit.dialog.tncContext.tnc3') }}</li>
      <li>
        <BlueTableWrapper>
          <el-table :data="tncTableData" class="desc-table">
            <el-table-column min-width="180">
              <template #header>
                <div>{{ $t('promotion.firstTimeDeposit.dialog.grossDeposit') }}</div>
                <div>({{ $t('promotion.firstTimeDeposit.dialog.grossDepositDesc') }})</div>
              </template>
              <template slot-scope="scope"> {{ scope.row.grossDeposit }} </template>
            </el-table-column>
            <el-table-column min-width="180">
              <template #header>
                <div>{{ $t('promotion.firstTimeDeposit.dialog.cbVoucher') }}</div>
              </template>
              <template slot-scope="scope"> {{ scope.row.cbVoucher }} </template>
            </el-table-column>
          </el-table>
        </BlueTableWrapper>
        <div class="dialog-table-desc">{{ $t('promotion.firstTimeDeposit.dialog.tableDesc') }}</div>
      </li>
      <li>{{ $t('promotion.firstTimeDeposit.dialog.tncContext.tnc4') }}</li>
      <li>{{ $t('promotion.firstTimeDeposit.dialog.tncContext.tnc5') }}</li>
    </ol>
  </AgreeTncDialog>
</template>

<script>
import AgreeTncDialog from '@/components/promotion/common/AgreeTncDialog.vue';
import BlueTableWrapper from '@/components/promotion/common/BlueTableWrapper.vue';

export default {
  name: 'FtdAgreeTnc',
  props: {
    agreeTncVisible: {
      type: Boolean,
      default: false
    },
    accountData: {
      type: Object,
      default: () => ({})
    }
  },
  components: { AgreeTncDialog, BlueTableWrapper },
  data() {
    return {
      visible: this.agreeTncVisible,
      tncTableData: [
        {
          grossDeposit: '≥$200',
          cbVoucher: '$5'
        },
        {
          grossDeposit: '≥$500',
          cbVoucher: '$10'
        },
        {
          grossDeposit: '≥$1000',
          cbVoucher: '$20'
        },
        {
          grossDeposit: '≥$2000',
          cbVoucher: '$50'
        }
      ]
    };
  },
  watch: {
    agreeTncVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateAgreeTncVisible(bool);
    }
  },
  methods: {
    activateSuccess() {
      this.$emit('activateSuccess');
    },
    updateAgreeTncVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:agreeTncVisible', bool);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-table-desc {
  color: $future-blue;
  margin-top: 24px;
  margin-bottom: 12px;
}
</style>
