<template>
  <section v-cloak>
    <!-- banner -->
    <div class="banner-container">
      <div v-if="propHasBanner" class="new-banner-image" :style="propBannerStyle">
        <slot name="bannerItem"></slot>
      </div>
    </div>

    <!-- main -->
    <div class="content-box">
      <div class="inner">
        <div class="inner-container">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PromotionTemplate',
  props: {
    bannerStyle: {
      type: Object,
      default: () => ({})
    },
    hasBanner: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    propBannerStyle() {
      return this.bannerStyle;
    },
    propHasBanner() {
      return this.hasBanner;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/common/promotion-base-style.scss';
</style>
