<template>
  <PromotionTemplate
    :bannerStyle="{ backgroundImage: `url(${rwdBannerBg})`, backgroundPosition: 'left' }"
    :hasBanner="banner"
  >
    <!-- banner -->
    <template #bannerItem>
      <BannerItemV2 v-bind="banner" :lang="imgLang" @getCurrentBannerRwdClass="getCurrentBannerRwdClass" />
    </template>

    <!-- content -->
    <template #content>
      <MainPage @activeOptIn="init" />
    </template>
  </PromotionTemplate>
</template>

<script>
// components
import PromotionTemplate from '@/components/promotion/common/PromotionTemplate.vue';
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import MainPage from '@/components/promotion/firstTimeDeposit/MainPage.vue';
// vuex
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'FirstTimeDeposit',
  components: {
    PromotionTemplate,
    BannerItemV2,
    MainPage
  },
  data() {
    return {
      banner: null,
      bannerRwdClass: null
    };
  },
  computed: {
    imgLang() {
      return this.$store.state.common.lang;
    },
    ...mapGetters('promotion/firstTimeDeposit', ['campaignID']),
    rwdBannerBg() {
      if (this.banner?.img) {
        switch (this.bannerRwdClass) {
          case 'long':
            return this.banner?.imgLarge;
          case 'mid-long':
          case 'mid':
          case 'mid-short':
            return this.banner?.img;
          case 'short':
          case 'x-short':
            return this.banner?.imgMid;
          case 'xx-short':
            return this.banner?.imgSmall;
          default:
            return this.banner?.img;
        }
      }

      return null;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('promotion/firstTimeDeposit', ['actionFetchCampaignInfo']),
    init() {
      this.actionFetchCampaignInfo();
      this.getBannerSetting();
    },
    getBannerSetting() {
      const regulatorConfig = this.$config.banner(this.regulator).filter(el => el.promotionId === this.campaignID)[0];

      this.banner = { ...regulatorConfig };
    },
    getCurrentBannerRwdClass(bannerRwdClass) {
      this.bannerRwdClass = bannerRwdClass;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/common/promotion-base-style.scss';
</style>
